export default function () {
  const structuredData = computed(() => {
    return {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      name: 'TAFE NSW',
      url: 'https://www.tafensw.edu.au'
    }
  })

  return { structuredData }
}
